import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import type {
  GetResponse,
  PushResponse,
  Alert
} from '../types'

export const alertsApi = createApi({
  reducerPath: 'alertsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production'
      ? 'https://api.risk.room40.io/alerts/'
      : 'http://127.0.0.1:8000/alerts/'
    ),
    fetchFn: fetch,
    credentials: 'include'
  }),
  tagTypes: [ 'Alerts' ],
  endpoints: (builder) => ({
    getAlerts: builder.query<GetResponse<Alert[]> | null, undefined>({
      query () {
        return {
          url: '',
          method: 'GET'
        }
      },
      providesTags: ['Alerts']
    }),
    addAlerts: builder.mutation<PushResponse, { additions: Alert[] }>({
      query (args: { additions: Alert[] }) {
        return {
          url: '',
          method: 'POST',
          body: {
            additions: args.additions.map((edit) => {
                return {
                  ...edit,
                  userId: edit.user.userId,
                  accountId: edit.account.accountId
                }
              })
          }
        }
      },
      invalidatesTags: ['Alerts']
    }),
    editAlerts: builder.mutation<PushResponse, { edits: Alert[] }>({
      query (args: { edits: Alert[] }) {
        return {
          url: '',
          method: 'PATCH',
          body: {
            edits: args.edits.map((edit) => {
              return {
                ...edit,
                userId: edit.user.userId,
                accountId: edit.account.accountId
              }
            })
          }
        }
      },
      invalidatesTags: ['Alerts']
    }),
    deleteAlert: builder.mutation<PushResponse, { alertId: string }>({
      query (args: { alertId: string }) {
        return {
          url: `${args.alertId}/`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Alerts']
    })
  })
})

export const {
  useGetAlertsQuery,
  useAddAlertsMutation,
  useEditAlertsMutation,
  useDeleteAlertMutation
} = alertsApi
