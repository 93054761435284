import React, { useMemo, useState } from 'react'
import {
  Box,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  type ColumnDefinition,
  Modal,
  type Order,
  TableSkeleton,
  getComparator,
  stableSort
} from '@r40cap/ui'

import type { CollateralRow, InputType } from '../../types'
import type { CollateralItem } from '../../sdk/types'
import { getModalContent, getDecimals, getAbsoluteValueComparator } from './utils'
import SortableHeader from '../utils/SortableHeader'
import AccountTableBody from './AccountTableBody'

function CollateralTable (props: {
  rows: CollateralRow[]
  isFetching: boolean
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof CollateralItem
  ) => void
  columns: Array<ColumnDefinition<CollateralRow, InputType, CollateralItem, string>>
  showTotalMv: boolean
  showDiscoutedMv: boolean
}): React.JSX.Element {
  const {
    rows,
    isFetching,
    handleOpenEdit,
    columns,
    showTotalMv,
    showDiscoutedMv
  } = props
  const { palette } = useTheme()
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof CollateralRow>('marketValue')

  const handleRequestSort = (property: keyof CollateralRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const visibleRows = useMemo(
    () => {
      if (orderBy === 'marketValue') {
        return stableSort(rows, getAbsoluteValueComparator(order, orderBy))
      } else {
        return stableSort(rows, getComparator(order, orderBy))
      }
    },
    [order, orderBy, rows]
  )

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        borderRadius: '5px',
        height: '100%'
      }}
    >
      <Table
        sx={{
          tableLayout: 'fixed',
          height: '100%'
        }}
        stickyHeader
      >
        <SortableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={columns}
        />
        {
          isFetching
            ? <TableSkeleton numRows={5} columns={columns}/>
            : <AccountTableBody
              rows={visibleRows}
              handleOpenEdit={handleOpenEdit}
              columns={columns}
            />
        }
        {
          !isFetching && (showTotalMv || showDiscoutedMv) && <TableFooter
            sx={{
              position: 'sticky',
              bottom: 0
            }}
          >
              {
                showTotalMv && <TableRow sx={{ backgroundColor: palette.background.default }}>
                  <TableCell
                    colSpan={columns.length - 2}
                    align='left'
                    sx={{
                      borderBottom: 'none',
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1px'
                    }}
                  >
                    <Typography
                      sx={{
                        color: palette.tableHeaderText.main,
                        fontSize: '1rem'
                      }}
                    >
                      Total MV
                    </Typography>
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align='right'
                    sx={{
                      borderBottom: 'none',
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1px'
                    }}
                  >
                    <Typography
                      sx={{
                        color: palette.tableHeaderText.main,
                        fontSize: '1rem'
                      }}
                    >
                      ${visibleRows.reduce((acc, row) => acc + row.marketValue, 0).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>
              }
              {
                showDiscoutedMv && <TableRow sx={{ backgroundColor: palette.background.default }}>
                  <TableCell
                    colSpan={columns.length - 2}
                    align='left'
                    sx={{
                      borderBottom: 'none',
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      borderTopStyle: showTotalMv ? undefined : 'solid',
                      borderTopWidth: showTotalMv ? undefined : '1px'
                    }}
                  >
                    <Typography
                      sx={{
                        color: palette.tableHeaderText.main,
                        fontSize: '1rem'
                      }}
                    >
                      Discounted MV
                    </Typography>
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align='right'
                    sx={{
                      borderBottom: 'none',
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      borderTopStyle: showTotalMv ? undefined : 'solid',
                      borderTopWidth: showTotalMv ? undefined : '1px'
                    }}
                  >
                    <Typography
                      sx={{
                        color: palette.tableHeaderText.main,
                        fontSize: '1rem'
                      }}
                    >
                      ${visibleRows.reduce((acc, row) => acc + row.marketValue * row.haircut, 0).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>
              }
            </TableFooter>
        }
      </Table>
    </TableContainer>
  )
}

function CollateralView (props: {
  data: readonly CollateralItem[]
  isFetching: boolean
  columns: Array<ColumnDefinition<CollateralRow, InputType, CollateralItem>>
  editSubmission: (property: keyof CollateralItem, value: any, editedCurrencyId: string) => void
  showTotalMv?: boolean
  showDiscoutedMv?: boolean
}): React.JSX.Element {
  const {
    data,
    isFetching,
    columns,
    editSubmission,
    showTotalMv,
    showDiscoutedMv
  } = props
  const { palette } = useTheme()
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)
  const usedShowTotalMv = showTotalMv ?? false
  const usedShowDiscoutedMv = showDiscoutedMv ?? false

  function handleSubmission (value: any, selectedIds: readonly string[], property?: keyof CollateralItem): void {
    if (property !== undefined && selectedIds.length === 1) {
      editSubmission(property, value, selectedIds[0])
    }
  }

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center'
      }}
    >
      <Box sx={{ height: '8%' }}>
        <Typography
          sx={{
            color: palette.tableBodyText.main,
            fontSize: '1.5rem'
          }}
        >
          Collateral
        </Typography>
      </Box>
      <Box sx={{ width: '95%', height: '92%' }}>
        <CollateralTable
          rows={data.map((bal: CollateralItem) => {
            const [quantDec, pxDec] = getDecimals(bal.price)
            return {
              id: bal.id,
              ticker: bal.ticker,
              balance: bal.balance,
              price: bal.price,
              haircut: bal.haircut,
              multiplier: bal.multiplier,
              priceDecimals: pxDec,
              quantityDecimals: quantDec,
              marketValue: bal.balance * bal.price * bal.multiplier
            }
          })}
          isFetching={isFetching}
          handleOpenEdit={
            (
              itemId: string,
              inputType: InputType,
              label: string,
              editProperty: keyof CollateralItem
            ) => {
              setEditModalOpen(true)
              setEditModalContent(
                getModalContent(
                  inputType,
                  label,
                  editProperty,
                  () => { setEditModalOpen(false) },
                  handleSubmission,
                  [itemId],
                  () => {}
                )
              )
            }
          }
          columns={columns}
          showTotalMv={usedShowTotalMv}
          showDiscoutedMv={usedShowDiscoutedMv}
        />
      </Box>
      <Modal
        open={editModalOpen}
        handleClose={() => setEditModalOpen(false)}
      >
        {editModalContent}
      </Modal>
    </Stack>
  )
}

export default CollateralView
