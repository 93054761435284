import React from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { type ColumnDefinition } from '@r40cap/ui'

import type { CollateralItem, PositionItem } from '../sdk/types'
import AccountBody from './accounts/AccountBody'
import { InputType, CollateralRow, PositionRow } from '../types'
import InsufficientPermissions from './utils/InsufficientPermissions'
import usePermissions, { type Permission } from '../sdk/usePermissions'

function CounterpartyBody (props: {
  requiredPermission: Permission
  getHealthScore: (collateral: readonly CollateralItem[], positions: readonly PositionItem[]) => number
  collateralColumns: Array<ColumnDefinition<CollateralRow, InputType, CollateralItem>>
  positionColumns: Array<ColumnDefinition<PositionRow, InputType, PositionItem>>
  setHasEdited: (hasEdited: boolean) => void
  shockModalOpen: boolean
  setShockModalOpen: (open: boolean) => void
  showMmr?: boolean
  showTotalGrossNotional?: boolean
  showTotalMv?: boolean
  showDiscoutedMv?: boolean
}): React.JSX.Element {
  const {
    getHealthScore,
    collateralColumns,
    positionColumns,
    shockModalOpen,
    setShockModalOpen,
    setHasEdited,
    requiredPermission,
    showTotalMv,
    showDiscoutedMv,
    showMmr,
    showTotalGrossNotional
  } = props
  const permissionsContext = usePermissions()
  const { accountId } = useParams()

  if (requiredPermission !== undefined && !permissionsContext[requiredPermission]) {
    return <InsufficientPermissions/>
  }

  return (
    <Box sx={{ height: '93%' }}>
      {
        accountId === undefined
          ? <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <h1>Select Account</h1>
          </Box>
          : <AccountBody
            getHealthScore={getHealthScore}
            accountId={accountId}
            positionColumns={positionColumns}
            collateralColumns={collateralColumns}
            setHasEdited={setHasEdited}
            shockModalOpen={shockModalOpen}
            setShockModalOpen={setShockModalOpen}
            showMmr={showMmr}
            showTotalGrossNotional={showTotalGrossNotional}
            showTotalMv={showTotalMv}
            showDiscoutedMv={showDiscoutedMv}
          />
      }
    </Box>
  )
}

export default CounterpartyBody
