import React, { useState, useEffect } from 'react'
import { Autocomplete, type AutocompleteOption } from '@r40cap/ui'

import { configApi } from '../reducers'
import type { Account } from '../types'

interface AccountInputProps {
  setAccount: (account: Account | null) => void
  mainColor: string
  secondaryColor: string
  spacing?: number
  counterpartyIdFilter?: string
}

export function AccountInput (props: AccountInputProps): React.JSX.Element {
  const {
    setAccount,
    mainColor,
    secondaryColor,
    counterpartyIdFilter
  } = props
  const { data } = configApi.useGetAccountsQuery({ counterpartyId: counterpartyIdFilter })
  const [accountOptions, setAccountOptions] = useState<ReadonlyArray<AutocompleteOption<string>>>([])
  const [accountChoice, setAccountChoice] = useState<AutocompleteOption<string> | null>(null)

  function trySetAccount (id: string | null): void {
    if (id === null) {
      setAccount(null)
    } else {
      const found = data?.data.find((data) => data.accountId === id)
      setAccount(found ?? null)
    }
  }

  useEffect(() => {
    if (data !== null && data !== undefined) {
      const options = data.data.map((account) => {
        if (counterpartyIdFilter !== undefined) {
          return {
            id: account.accountId,
            label: account.name
          }
        } else {
          return {
            id: account.accountId,
            label: `${account.counterparty.name} - ${account.name}`
          }
        }
      })
      setAccountOptions(options)
    }
  }, [data, counterpartyIdFilter])

  return <Autocomplete<string>
    trySetObject={trySetAccount}
    options={accountOptions}
    choice={accountChoice}
    setChoice={setAccountChoice}
    label='Account'
    activeColor={mainColor}
    inactiveColor={secondaryColor}
    prefillId={undefined}
  />
}
