import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import {
  ACCOUNTS_SLUG,
  COUNTERPARTIES_SLUG
} from './slugs'
import type {
  GetResponse,
  PushResponse,
  Account,
  Counterparty
} from '../types'

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production'
      ? 'https://api.risk.room40.io/config/'
      : 'http://127.0.0.1:8000/config/'
    ),
    fetchFn: fetch,
    credentials: 'include'
  }),
  tagTypes: [
    'Counterparties',
    'Accounts'
  ],
  endpoints: (builder) => ({
    getCounterparties: builder.query<GetResponse<Counterparty[]> | null, undefined>({
      query () {
        return {
          url: `${COUNTERPARTIES_SLUG}/`,
          method: 'GET'
        }
      },
      providesTags: ['Counterparties']
    }),
    addCounterparties: builder.mutation<PushResponse, { additions: Counterparty[] }>({
      query (args: { additions: Counterparty[] }) {
        return {
          url: `${COUNTERPARTIES_SLUG}/`,
          method: 'POST',
          body: {
            additions: args.additions
          }
        }
      },
      invalidatesTags: ['Counterparties']
    }),
    editCounterparties: builder.mutation<PushResponse, { edits: Counterparty[] }>({
      query (args: { edits: Counterparty[] }) {
        return {
          url: `${COUNTERPARTIES_SLUG}/`,
          method: 'PATCH',
          body: {
            edits: args.edits
          }
        }
      },
      invalidatesTags: ['Counterparties']
    }),
    getAccounts: builder.query<GetResponse<Account[]> | null, { counterpartyId?: string }>({
      query (args: { counterpartyId?: string }) {
        return {
          url: `${ACCOUNTS_SLUG}/`,
          params: { counterpartyId: args.counterpartyId },
          method: 'GET'
        }
      },
      providesTags: ['Accounts', 'Counterparties']
    }),
    addAccounts: builder.mutation<PushResponse, { additions: Account[] }>({
      query (args: { additions: Account[] }) {
        return {
          url: `${ACCOUNTS_SLUG}/`,
          method: 'POST',
          body: {
            additions: args.additions.map((acc) => {
              return {
                ...acc,
                counterpartyId: acc.counterparty.counterpartyId
              }
            })
          }
        }
      },
      invalidatesTags: ['Accounts']
    }),
    editAccounts: builder.mutation<PushResponse, { edits: Account[] }>({
      query (args: { edits: Account[] }) {
        return {
          url: `${ACCOUNTS_SLUG}/`,
          method: 'PATCH',
          body: {
            edits: args.edits.map((acc) => {
              return {
                ...acc,
                counterpartyId: acc.counterparty.counterpartyId
              }
            })
          }
        }
      },
      invalidatesTags: ['Accounts']
    })
  })
})

export const {
  useGetCounterpartiesQuery,
  useAddCounterpartiesMutation,
  useEditCounterpartiesMutation,
  useGetAccountsQuery,
  useAddAccountsMutation,
  useEditAccountsMutation
} = configApi
