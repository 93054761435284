import React, { useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Box, useTheme } from '@mui/material'
import { EditableTable, useRequestSnackbar } from '@r40cap/ui'

import type { InputType, AccountRow } from './config/types'
import { accountColumns } from './config/constants'
import { getModalContent } from './config/utils'
import { isApiError } from '../errors'
import { configApi } from '../sdk/reducers'
import type { Account } from '../sdk/types'
import usePermissions from '../sdk/usePermissions'
import InsufficientPermissions from './utils/InsufficientPermissions'
import { useEventSystem } from '../hooks/useEventSystem'
import { REFRESH, PUSH } from '../constants/events'

function ConfigBody (props: {
  setHasEdited: (hasEdited: boolean) => void
}): React.JSX.Element {
  const { setHasEdited } = props
  const { palette } = useTheme()
  const [editedList, setEditedList] = useState<readonly string[]>([])
  const [selected, setSelected] = useState<readonly string[]>([])
  const [editedData, setEditedData] = useState<Account[]>([])
  const { hasConfigAccess } = usePermissions()
  const { showSnackbar } = useRequestSnackbar()
  const { subscribe } = useEventSystem()

  const { data, isFetching, refetch } = configApi.useGetAccountsQuery({})
  const [postEditsMutation] = configApi.useEditAccountsMutation()

  const handleEdit = (property: keyof Account, value: any, selected: readonly string[]): void => {
    const updatedData = editedData.map(item =>
      selected.includes(item.accountId) ? { ...item, [property]: value } : item
    )
    setEditedData(updatedData)
    const editedSet = new Set(editedList)
    selected.forEach((value) => {
      editedSet.add(value)
    })
    setEditedList(Array.from(editedSet))
    setHasEdited(true)
  }

  const handleRefresh = useCallback(() => {
    refetch()
      .catch((err) => { console.error(err) })
      .then(() => {
        if (data !== null && data !== undefined) {
          setEditedData(data.data)
          setHasEdited(false)
        }
      })
  }, [refetch, data, setHasEdited])

  useEffect(() => {
    const unsubscribe = subscribe(REFRESH, handleRefresh)
    return () => {
      unsubscribe()
    }
  }, [subscribe, handleRefresh])
  
  const handlePush = useCallback(() => {
    const editedEditObjects = editedData.filter(data => editedList.includes(data.accountId))
    if (editedEditObjects.length > 0) {
      showSnackbar({
        isOpen: true,
        message: 'Pushing Instrument Edits',
        status: 'processing'
      })
      postEditsMutation({ edits: editedEditObjects })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.status === 400 || value.error.status === 403
              ? value.error.data.error
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Edits Pushed',
              status: 'success'
            })
            setEditedList([])
            setHasEdited(false)
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push edits',
            status: 'error'
          })
        })
    }
  }, [editedData, editedList, postEditsMutation, setHasEdited, showSnackbar])

  useEffect(() => {
    const unsubscribe = subscribe(PUSH, handlePush)
    return () => {
      unsubscribe()
    }
  }, [subscribe, handlePush])

  function handleSubmission (value: any, selectedIds: readonly string[], property?: keyof Account): void {
    if (property !== undefined) {
      handleEdit(property, value, selectedIds)
    }
  }
  
  const getPreset = (rowId: string, property: keyof Account): any => {
    const relevantObject = editedData.find((dataRow: Account) => dataRow.accountId === rowId)
    if (relevantObject === undefined) {
      return undefined
    } else {
      return relevantObject[property]
    }
  }

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setEditedData(data.data)
    }
  }, [data])
  
  if (!hasConfigAccess) {
    return <InsufficientPermissions/>
  }

  return (
    <Box sx={{ height: '93%' }}>
      <EditableTable<AccountRow, Account, InputType, string>
        rows={editedData.map((acc) => {
          return {
            id: acc.accountId,
            name: acc.name,
            counterparty: acc.counterparty.name,
            apiSecret: acc.apiSecret ?? '',
            apiId: acc.apiId ?? ''
          }
        }) ?? []}
        columns={accountColumns}
        selected={selected}
        setSelected={setSelected}
        defaultOrderBy='counterparty'
        defaultOrder='asc'
        isFetching={isFetching}
        getPreset={getPreset}
        getModalContent={(
          inputType: InputType,
          label: string,
          editProperty: keyof Account,
          closeModal: () => void,
          newSelected: readonly string[],
          initialValue: any
        ) => {
          return getModalContent(
            inputType,
            label,
            editProperty,
            closeModal,
            handleSubmission,
            newSelected,
            setSelected,
            initialValue
          )
        }}
        checkboxColor={palette.accent.main}
        backgroundColor={palette.primary.main}
        headerBackgroundColor={palette.background.default}
        headerTextColor={palette.tableHeaderText.main}
        headerActiveTextColor={palette.accent.main}
        bodyDefaultTextColor={palette.tableBodyText.main}
        redTextColor='red'
        greenTextColor='green'
        bodyPrimaryBackgroundColor={palette.tertiary.main}
      />
      <Outlet />
    </Box>
  )
}

export default ConfigBody
