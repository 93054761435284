import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'

import { isApiError } from '../errors'
import { alertsApi } from '../sdk/reducers'

function DeleteAlertModal (): React.JSX.Element {
  const navigate = useNavigate()
  const { alertId } = useParams()
  const { palette } = useTheme()
  const { showSnackbar } = useRequestSnackbar()
  const [performDelete] = alertsApi.useDeleteAlertMutation()

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/alerts')
    }
  }

  function deleteAlert (): void {
    if (alertId !== undefined) {
      showSnackbar({
        isOpen: true,
        message: 'Deleting Alert',
        status: 'processing'
      })
      performDelete({ alertId })
        .then((value) => {
          console.log(value)
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.status === 400 || value.error.status === 403
              ? value.error.data.error
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Deleted Alert',
              status: 'success'
            })
            exitModal()
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to delete Alert',
            status: 'error'
          })
        })
    }
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box
        sx={{
          width: '25vw',
          padding: '20px'
        }}
      >
        <Stack direction={'column'} spacing={2}>
          <Typography
            variant='h2'
            sx={{ color: palette.tableHeaderText.main }}
          >
            Delete Alert?
          </Typography>
          <Typography
            variant='h5'
            sx={{ color: palette.tableHeaderText.main }}
          >
            This action cannot be undone.
            
          </Typography>
          <Stack direction={'row'} spacing={1}>
            <Button
              variant='outlined'
              onClick={exitModal}
              sx={{
                outlineColor: palette.accent.main,
                color: palette.accent.main,
                width: '100%'
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={deleteAlert}
              style={{
                width: '100%',
                backgroundColor: palette.accent.main,
                color: palette.secondary.main
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}

export default DeleteAlertModal
