import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, IconButton, Stack, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { RefreshButton } from '@r40cap/ui'

import AccountInput from '../../sdk/AccountInput'
import { useEventSystem } from '../../hooks/useEventSystem'
import { PUSH, REFRESH, RESET } from '../../constants/events'

function ControlsPanel (props: {
  hasEdited: boolean
  setHasEdited: (value: boolean) => void
  setShockModalOpen: (value: boolean) => void
}) {
  const {
    hasEdited,
    setHasEdited,
    setShockModalOpen
  } = props
  const { publish } = useEventSystem()
  const location = useLocation()
  const navigate = useNavigate()
  const { palette } = useTheme()
  const splitPath = location.pathname.split('/')
  const viewType = splitPath[1]

  const isConfig = viewType === 'config'
  const isAlerts = viewType === 'alerts'
  const accountIdChosen = splitPath.length > 2

  return (
    <Box
      sx={{
        height: '7%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '20%',
          minWidth: '150px'
        }}
      >
        {
          !isConfig && !isAlerts && <AccountInput
            setAccount={(account) => {
              setHasEdited(false)
              if (account === null) {
                navigate(`/${viewType}`)
              } else {
                navigate(`/${viewType}/${account.accountId}`)
              }
            }}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            counterpartyIdFilter={viewType}
          />
        }
      </Box>
      <Stack
        direction={'row'}
        spacing={1}
        alignContent={'center'}
        sx={{ height: '50%' }}
      >
        {
          hasEdited && <Button
            variant='outlined'
            onClick={() => {
              if (isConfig || isAlerts) {
                publish(PUSH)
              } else {
                publish(RESET)
              }
            }}
            sx={{
              outlineColor: palette.accent.main,
              color: palette.accent.main
            }}
          >
            {(isConfig || isAlerts) ? 'PUSH' : 'RESET'}
          </Button>
        }
        {
          !isConfig && accountIdChosen && <Button
            variant='outlined'
            onClick={() => setShockModalOpen(true)}
            sx={{
              outlineColor: palette.accent.main,
              color: palette.accent.main
            }}
          >
            SHOCK
          </Button>
        }
        {
          (isConfig || isAlerts) && <IconButton
            onClick={() => { navigate(`/${viewType}/new`) }}
          >
            <AddIcon />
          </IconButton>
        }
        <RefreshButton
          refreshFunction={() => { publish(REFRESH) }}
          iconColor={palette.primary.main}
          buttonColor={palette.accent.main}
        />
      </Stack>
    </Box>
  )
}

export default ControlsPanel
