import React from 'react'
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

interface BetaSelectionProps {
  setBetaDays: (betaDays: number) => void
  betaDays: number
}

export function BetaSelection (props: BetaSelectionProps): React.JSX.Element {
  const { setBetaDays, betaDays } = props

  const handleCallOrPutChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: number | null,
  ) => {
    if (newValue !== null) {
      setBetaDays(newValue)
    }
  }

  return (
    <ToggleButtonGroup
      value={betaDays}
      exclusive
      onChange={handleCallOrPutChange}
      style={{ width: '100%', height: '100%' }}
    >
      <ToggleButton
        value={14}
        style={{ width: '50%' }}
      >
        <Typography>14D Beta</Typography>
      </ToggleButton>
      <ToggleButton
        value={90}
        style={{ width: '50%' }}
      >
        <Typography>90D Beta</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
