import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'

import { isApiError } from '../errors'
import { alertsApi } from '../sdk/reducers'
import type { Alert } from '../sdk/types'
import usePermissions from '../sdk/usePermissions'
import InsufficientPermissions from './utils/InsufficientPermissions'
import { Outlet } from 'react-router-dom'
import AlertsTable from './alerts/AlertsTable'
import { InputType } from '../types'
import { getModalContent } from './alerts/utils'
import { useEventSystem } from '../hooks/useEventSystem'
import { PUSH, REFRESH } from '../constants/events'

function AlertsBody (props: {
  setHasEdited: (hasEdited: boolean) => void
}): React.JSX.Element {
  const { setHasEdited } = props
  const { subscribe } = useEventSystem()
  const [editedList, setEditedList] = useState<readonly string[]>([])
  const [editedData, setEditedData] = useState<Alert[]>([])
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)
  const { hasAlertsAccess } = usePermissions()
  const { showSnackbar } = useRequestSnackbar()

  const { data, isFetching, refetch } = alertsApi.useGetAlertsQuery(undefined)
  const [postEditsMutation] = alertsApi.useEditAlertsMutation()

  const handleRefresh = useCallback(() => {
    refetch()
      .catch((err) => { console.error(err) })
      .then(() => {
        if (data !== null && data !== undefined) {
          setEditedData(data.data)
          setHasEdited(false)
        }
      })
  }, [refetch, data, setHasEdited])

  useEffect(() => {
    const unsubscribe = subscribe(REFRESH, handleRefresh)
    return () => {
      unsubscribe()
    }
  }, [subscribe, handleRefresh])

  const handleEdit = (value: any, alertId: string, property: keyof Alert): void => {
    const usedValue = property === 'movement' ? value / 100 : value
    const updatedData = editedData.map(item =>
      item.alertId === alertId ? { ...item, [property]: usedValue } : item
    )
    setEditedData(updatedData)
    const editedSet = new Set(editedList)
    editedSet.add(alertId)
    setEditedList(Array.from(editedSet))
    setHasEdited(true)
  }

  const handlePush = useCallback(() => {
    const editedEditObjects = editedData.filter(data => editedList.includes(data.alertId))
    if (editedEditObjects.length > 0) {
      showSnackbar({
        isOpen: true,
        message: 'Pushing Instrument Edits',
        status: 'processing'
      })
      postEditsMutation({ edits: editedEditObjects })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.status === 400 || value.error.status === 403
              ? value.error.data.error
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Edits Pushed',
              status: 'success'
            })
            setEditedList([])
            setHasEdited(false)
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push edits',
            status: 'error'
          })
        })
    }
  }, [editedData, editedList, postEditsMutation, setHasEdited, showSnackbar])

  useEffect(() => {
    const unsubscribe = subscribe(PUSH, handlePush)
    return () => {
      unsubscribe()
    }
  }, [subscribe, handlePush])

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setEditedData(data.data)
    }
  }, [data])
  
  if (!hasAlertsAccess) {
    return <InsufficientPermissions/>
  }

  return (
    <Box sx={{ height: '93%' }}>
      <AlertsTable
        rows={editedData.map((alert) => {
          return {
            id: alert.alertId,
            account: `${alert.account.counterpartyName} - ${alert.account.name}`,
            alertType: alert.alertType === 'live'
              ? 'Live'
              : 'Shocked',
            alertSide: alert.alertSide === 'under' ? 'U' : 'O',
            alertSideSign: alert.alertSide === 'under' ? -1 : 1,
            alertDeliveryMethod: alert.alertDeliveryMethod === 'slack_webhook'
              ? 'Slack'
              : alert.alertDeliveryMethod === 'fink_gpt'
                ? 'Fink GPT'
                : 'PagerDuty',
            deliveryIdentifier: alert.deliveryIdentifier ?? '',
            user: alert.user.username,
            userId: alert.user.userId,
            currencyId: alert.currencyId ?? '',
            movement: alert.movement !== null && alert.movement !== undefined ? alert.movement * 100 : '',
            healthThreshold: alert.healthThreshold,
            cooldownMinutes: alert.cooldownMinutes
          }
        })}
        isFetching={isFetching}
        handleOpenEdit={
          (
            itemId: string,
            inputType: InputType,
            label: string,
            editProperty: keyof Alert
          ) => {
            setEditModalOpen(true)
            setEditModalContent(
              getModalContent(
                inputType,
                label,
                editProperty,
                handleEdit,
                itemId,
                () => { setEditModalOpen(false) }
              )
            )
          }
        }
      />
      <Modal
        open={editModalOpen}
        handleClose={() => setEditModalOpen(false)}
      >
        {editModalContent}
      </Modal>
      <Outlet />
    </Box>
  )
}

export default AlertsBody
