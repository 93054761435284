import React from 'react'
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

import type { AlertSide } from '../types'

interface AlertSideInputProps {
  side: AlertSide
  setSide: (side: AlertSide) => void
}

export function AlertSideInput (props: AlertSideInputProps): React.JSX.Element {
  const { side, setSide } = props

  const handleCallOrPutChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: AlertSide | null,
  ) => {
    if (newValue !== null) {
      setSide(newValue)
    }
  }

  return (
    <ToggleButtonGroup
      value={side}
      exclusive
      onChange={handleCallOrPutChange}
      style={{ width: '100%', height: '100%' }}
    >
      <ToggleButton
        value={'under'}
        style={{ width: '100%' }}
      >
        <Typography fontSize={12}>Under</Typography>
      </ToggleButton>
      <ToggleButton
        value={'over'}
        style={{ width: '100%' }}
      >
        <Typography fontSize={12}>Over</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
