import React from 'react'

import { InputType } from '../../types'
import {
  AccountEditContent,
  AlertTypeEditContent,
  AlertSideEditContent,
  DeliveryMethodEditContent,
  IntegerEditContent,
  NumberEditContent,
  StringEditContent
} from '../modals/inputs'
import type { Alert } from '../../sdk/types'

export function getModalContent (
  inputType: InputType,
  label: string,
  editProperty: keyof Alert,
  handleSubmission: (value: any, alertId: string, property: keyof Alert) => void,
  alertId: string,
  closeModal: () => void
): React.JSX.Element {
  let content: React.JSX.Element = <></>

  function submit (value: any): void {
    handleSubmission(value, alertId, editProperty)
  }

  switch (inputType) {
    case InputType.ACCOUNT:
      content = <AccountEditContent
        closeModal={closeModal}
        submit={submit}
      />
      break
    case InputType.DELIVERY_METHOD:
      content = <DeliveryMethodEditContent
        closeModal={closeModal}
        submit={submit}
      />
      break
    case InputType.ALERT_TYPE:
      content = <AlertTypeEditContent
        closeModal={closeModal}
        submit={submit}
      />
      break
    case InputType.ALERT_SIDE:
      content = <AlertSideEditContent
        closeModal={closeModal}
        submit={submit}
      />
      break
    case InputType.FLOAT:
      content = <NumberEditContent
        title={label}
        closeModal={closeModal}
        submit={submit}
      />
      break
    case InputType.POS_FLOAT:
      content = <NumberEditContent
        title={label}
        closeModal={closeModal}
        submit={(sub: number) => { submit(Math.abs(sub)) }}
      />
      break
    case InputType.POS_INTEGER:
      content = <IntegerEditContent
        title={label}
        closeModal={closeModal}
        submit={(sub: number) => { submit(Math.abs(sub)) }}
      />
      break
    default:
      content = <StringEditContent
        closeModal={closeModal}
        submit={submit}
        title={label}
      />
  }
  return content
}
