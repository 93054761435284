import React from 'react'
import { Box, Tooltip, Typography, useTheme } from '@mui/material'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'

import type { PlatformRiskMetric } from '../../sdk/types'

function HealthScoreDisplay (props: {
  score: number | null
  platformRiskMetric?: PlatformRiskMetric
  isFetching: boolean
}): React.JSX.Element {
  const {
    score,
    platformRiskMetric,
    isFetching
  } = props
  const { palette } = useTheme()

  const textColor = score === null || isFetching
    ? palette.tableBodyText.main
    : score >= 3.5
      ? palette.goodColor.main
      : score >= 1.1
        ? palette.dangerColor.main
        : palette.errorColor.main

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: palette.tertiary.main,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {
        score === null || isFetching
          ? <HorizontalRuleIcon
              sx={{
                fontSize: '3.5rem',
                color: textColor,
                alignContent: 'center',
              }}
            />
          : score === Infinity
            ? <AllInclusiveIcon
                sx={{
                  fontSize: '3.5rem',
                  color: textColor,
                  alignContent: 'center',
                }}
              />
            : (
              <Box sx={{ textAlign: 'center', position: 'relative' }}>
                <Typography
                  sx={{
                    fontSize: '3.5rem',
                    fontWeight: 'bold',
                    color: textColor,
                  }}
                >
                  {score.toLocaleString('en-US', { maximumFractionDigits: 5 })}
                </Typography>
                {platformRiskMetric !== undefined && (
                  <Tooltip
                    title={`Live ${platformRiskMetric.name} (Not affected by inputs)`}
                    placement='bottom'
                    arrow
                  >
                    <Typography
                      sx={{
                        fontSize: '1.5rem',
                        color: palette.quietText.main,
                        position: 'absolute',
                        top: '100%',
                        width: '100%',
                        textAlign: 'center',
                        marginTop: '0.5rem',
                      }}
                    >
                      {platformRiskMetric.value.toLocaleString('en-US', { maximumFractionDigits: 5 })}
                    </Typography>
                  </Tooltip>
                )}
              </Box>
            )
      }
    </Box>
  )
}

export default HealthScoreDisplay
