import React from 'react'
import { Box, Typography } from '@mui/material'

function InsufficientPermissions () {
  return (
    <Box sx={{
      height: '93%',
      width: '100%',
      alignContent: 'center',
      justifyItems: 'center'
    }}>
      <Typography align='center' variant='h4'>Insufficient Permissions</Typography>
    </Box>
  )
}

export default InsufficientPermissions
