import React, { createContext, useCallback, ReactNode } from 'react'

type EventCallback = (data: any) => void
type Listeners = Record<string, EventCallback[]>

export interface EventContextType {
  subscribe: (eventName: string, callback: EventCallback) => () => void
  publish: (eventName: string, data?: any) => void
}

export const EventContext = createContext<EventContextType | undefined>(undefined)

interface EventProviderProps {
  children: ReactNode
}

export const EventProvider: React.FC<EventProviderProps> = ({ children }) => {
  const listeners: Listeners = React.useMemo(() => ({}), [])

  const subscribe = useCallback((eventName: string, callback: EventCallback) => {
    if (!listeners[eventName]) {
      listeners[eventName] = []
    }
    listeners[eventName].push(callback)
    return () => {
      listeners[eventName] = listeners[eventName].filter(cb => cb !== callback)
    }
  }, [listeners])

  const publish = useCallback((eventName: string, data?: any) => {
    if (listeners[eventName]) {
      listeners[eventName].forEach(callback => callback(data))
    }
  }, [listeners])

  return (
    <EventContext.Provider value={{ subscribe, publish }}>
      {children}
    </EventContext.Provider>
  )
}
