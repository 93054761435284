import { useContext } from 'react'
import { EventContext, EventContextType } from '../context/EventContext'

export const useEventSystem = (): EventContextType => {
  const context = useContext(EventContext)
  if (context === undefined) {
    throw new Error('useEventSystem must be used within an EventProvider')
  }
  return context
}
