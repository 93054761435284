import { createSlice } from '@reduxjs/toolkit'
import { type RootState } from '../../store'

interface themeState {
  mode: 'light' | 'dark'
}

const initialState: themeState = {
  mode: 'light'
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    themeToggle (state) {
      state.mode = state.mode === 'light' ? 'dark' : 'light'
    }
  }
})

export const { themeToggle } = themeSlice.actions

export const getMode = (state: RootState): 'light' | 'dark' => state.theme.mode

export default themeSlice.reducer
