import React from 'react'
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

import type { AlertType } from '../types'

interface AlertTypeInputProps {
  type: AlertType
  setType: (method: AlertType) => void
}

export function AlertTypeInput (props: AlertTypeInputProps): React.JSX.Element {
  const { type, setType } = props

  const handleCallOrPutChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: AlertType | null,
  ) => {
    if (newValue !== null) {
      setType(newValue)
    }
  }

  return (
    <ToggleButtonGroup
      value={type}
      exclusive
      onChange={handleCallOrPutChange}
      style={{ width: '100%', height: '100%' }}
    >
      <ToggleButton
        value={'live'}
        style={{ width: '100%' }}
      >
        <Typography fontSize={12}>Live</Typography>
      </ToggleButton>
      <ToggleButton
        value={'shocked'}
        style={{ width: '100%' }}
      >
        <Typography fontSize={12}>Shocked</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
