import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import {
  BETAS_SLUG
} from './slugs'
import type {
  GetResponse,
  Beta
} from '../types'

export const marketDataApi = createApi({
  reducerPath: 'marketDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production'
      ? 'https://api.risk.room40.io/marketData/'
      : 'http://127.0.0.1:8000/marketData/'
    ),
    fetchFn: fetch,
    credentials: 'include'
  }),
  tagTypes: [ 'Betas' ],
  endpoints: (builder) => ({
    getBetas: builder.query<GetResponse<Beta[]> | null, {}>({
      query (args: {}) {
        return {
          url: `${BETAS_SLUG}/`,
          method: 'GET'
        }
      },
      providesTags: ['Betas']
    })
  })
})

export const {
  useGetBetasQuery
} = marketDataApi
