import { createContext, useContext } from 'react'

export interface PermissionsStatus {
  userId?: string
  username?: string
  hasCoinbaseAccess: boolean
  hasStonexAccess: boolean
  hasOkxAccess: boolean
  hasConfigAccess: boolean
  hasRpmsAccess: boolean
  hasAlertsAccess: boolean
}

export const initialState: PermissionsStatus = {
  userId: undefined,
  username: undefined,
  hasCoinbaseAccess: false,
  hasStonexAccess: false,
  hasOkxAccess: false,
  hasConfigAccess: false,
  hasRpmsAccess: false,
  hasAlertsAccess: false
}

export interface PermissionsContextType {
  userId?: string
  username?: string
  hasCoinbaseAccess: boolean
  hasStonexAccess: boolean
  hasOkxAccess: boolean
  hasConfigAccess: boolean
  hasRpmsAccess: boolean
  hasAlertsAccess: boolean
}

export type Permission = {
    [K in keyof PermissionsContextType]: PermissionsContextType[K] extends boolean ? K : never
}[keyof PermissionsContextType]

export const PermissionsContext = createContext<PermissionsContextType>({
  ...initialState
})

export const usePermissions = (): PermissionsContextType => {
  return useContext(PermissionsContext)
}
