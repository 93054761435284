import React, { useMemo, useState } from 'react'
import {
  Box,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  type ColumnDefinition,
  Modal,
  TableSkeleton,
  Order,
  getComparator,
  stableSort
} from '@r40cap/ui'

import type { InputType, PositionRow } from '../../types'
import type { PositionItem } from '../../sdk/types'
import { getModalContent, getDecimals, getAbsoluteValueComparator } from './utils'
import AccountTableBody from './AccountTableBody'
import SortableHeader from '../utils/SortableHeader'

function PositionsTable (props: {
  rows: PositionRow[]
  isFetching: boolean
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof PositionItem
  ) => void
  columns: Array<ColumnDefinition<PositionRow, InputType, PositionItem, string>>
  showTotalGrossNotional: boolean
  showMmr: boolean
}): React.JSX.Element {
  const {
    rows,
    isFetching,
    handleOpenEdit,
    columns,
    showTotalGrossNotional,
    showMmr
  } = props
  const { palette } = useTheme()
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof PositionRow>('marketValue')

  const handleRequestSort = (property: keyof PositionRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const visibleRows = useMemo(
    () => {
      if (orderBy === 'marketValue') {
        return stableSort(rows, getAbsoluteValueComparator(order, orderBy))
      } else {
        return stableSort(rows, getComparator(order, orderBy))
      }
    },
    [order, orderBy, rows]
  )

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        borderRadius: '5px',
        height: '100%'
      }}
    >
      <Table
        stickyHeader
        sx={{ tableLayout: 'fixed', height: '100%' }}
      >
        <SortableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={columns}
        />
        {
          isFetching
            ? <TableSkeleton numRows={5} columns={columns}/>
            : <AccountTableBody
              rows={visibleRows}
              handleOpenEdit={handleOpenEdit}
              columns={columns}
            />
        }
        {
          !isFetching && (showTotalGrossNotional || showMmr) && <TableFooter
            sx={{
              position: 'sticky',
              bottom: 0
            }}
          >
              {
                showTotalGrossNotional && <TableRow sx={{ backgroundColor: palette.background.default }}>
                  <TableCell
                    colSpan={columns.length - 2}
                    align='left'
                    sx={{
                      borderBottom: 'none',
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1px'
                    }}
                  >
                    <Typography
                      sx={{
                        color: palette.tableHeaderText.main,
                        fontSize: '1rem'
                      }}
                    >
                      Gross Notional
                    </Typography>
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align='right'
                    sx={{
                      borderBottom: 'none',
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1px'
                    }}
                  >
                    <Typography
                      sx={{
                        color: palette.tableHeaderText.main,
                        fontSize: '1rem'
                      }}
                    >
                      ${visibleRows.reduce((acc, row) => acc + Math.abs(row.marketValue), 0).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>
              }
              {
                showMmr && <TableRow sx={{ backgroundColor: palette.background.default }}>
                  <TableCell
                    colSpan={columns.length - 2}
                    align='left'
                    sx={{
                      borderBottom: 'none',
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      borderTopStyle: showTotalGrossNotional ? undefined : 'solid',
                      borderTopWidth: showTotalGrossNotional ? undefined : '1px'
                    }}
                  >
                    <Typography
                      sx={{
                        color: palette.tableHeaderText.main,
                        fontSize: '1rem'
                      }}
                    >
                      MMR
                    </Typography>
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align='right'
                    sx={{
                      borderBottom: 'none',
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      borderTopStyle: showTotalGrossNotional ? undefined : 'solid',
                      borderTopWidth: showTotalGrossNotional ? undefined : '1px'
                    }}
                  >
                    <Typography
                      sx={{
                        color: palette.tableHeaderText.main,
                        fontSize: '1rem'
                      }}
                    >
                      ${visibleRows.reduce((acc, row) => acc + Math.abs(row.marketValue) * row.marginRequirementCoefficient, 0).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>
              }
            </TableFooter>
        }
      </Table>
    </TableContainer>
  )
}

function PositionsView (props: {
  data: readonly PositionItem[]
  isFetching: boolean
  columns: Array<ColumnDefinition<PositionRow, InputType, PositionItem>>
  editSubmission: (property: keyof PositionItem, value: any, editedPositionId: string) => void
  priceMap: Map<string, number>
  showTotalGrossNotional?: boolean
  showMmr?: boolean
}): React.JSX.Element {
  const {
    data,
    isFetching,
    columns,
    editSubmission,
    priceMap,
    showTotalGrossNotional,
    showMmr
  } = props
  const { palette } = useTheme()
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)
  const usedShowTotalGrossNotional = showTotalGrossNotional ?? false
  const usedShowMmr = showMmr ?? false

  function handleSubmission (value: any, selectedIds: readonly string[], property?: keyof PositionItem): void {
    if (property !== undefined && selectedIds.length === 1) {
      editSubmission(property, value, selectedIds[0])
    }
  }

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center'
      }}
    >
      <Box sx={{ height: '8%' }}>
        <Typography
          sx={{
            color: palette.tableBodyText.main,
            fontSize: '1.5rem'
          }}
        >
          Positions
        </Typography>
      </Box>
      <Box sx={{ width: '95%', height: '92%' }}>
        <PositionsTable
          rows={data.map((psn: PositionItem) => {
            const [quantDec, pxDec] = getDecimals(psn.price)
            const frSign = psn.quantity < 0 ? 1 : -1
            return {
              id: psn.id,
              ticker: psn.ticker,
              quantity: psn.quantity,
              price: psn.price,
              unrealizedPnl: psn.settlementInfo?.unrealizedPnl ?? 0,
              settlementCurrencyId: psn.settlementInfo?.settlementCurrencyId ?? '',
              marginRequirementCoefficient: psn.marginRequirementCoefficient,
              multiplier: psn.multiplier,
              priceDecimals: pxDec,
              quantityDecimals: quantDec,
              marketValue: psn.quantity * psn.price * psn.multiplier * (priceMap.get(psn.quoteCurrencyId) ?? 1),
              annualizedFundingRatePct: psn.annualizedFundingRate === undefined ? 0 : psn.annualizedFundingRate * 100,
              ourAnnualizedFundingRatePct: psn.annualizedFundingRate === undefined ? 0 : psn.annualizedFundingRate * 100 * frSign
            }
          })}
          isFetching={isFetching}
          handleOpenEdit={
            (
              itemId: string,
              inputType: InputType,
              label: string,
              editProperty: keyof PositionItem
            ) => {
              setEditModalOpen(true)
              setEditModalContent(
                getModalContent(
                  inputType,
                  label,
                  editProperty,
                  () => { setEditModalOpen(false) },
                  handleSubmission,
                  [itemId],
                  () => {}
                )
              )
            }
          }
          columns={columns}
          showTotalGrossNotional={usedShowTotalGrossNotional}
          showMmr={usedShowMmr}
        />
      </Box>
      <Modal
        open={editModalOpen}
        handleClose={() => setEditModalOpen(false)}
      >
        {editModalContent}
      </Modal>
    </Stack>
  )
}

export default PositionsView
