import React from 'react'
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

import type { AlertDeliveryMethod } from '../types'

interface AlertDeliveryMethodInputProps {
  deliveryMethod: AlertDeliveryMethod
  setDeliveryMethod: (method: AlertDeliveryMethod) => void
}

export function AlertDeliveryMethodInput (props: AlertDeliveryMethodInputProps): React.JSX.Element {
  const {
    deliveryMethod,
    setDeliveryMethod
  } = props

  const handleCallOrPutChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: AlertDeliveryMethod | null,
  ) => {
    if (newValue !== null) {
      setDeliveryMethod(newValue)
    }
  }

  return (
    <ToggleButtonGroup
      value={deliveryMethod}
      exclusive
      onChange={handleCallOrPutChange}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex'
      }}
    >
      <ToggleButton
        value={'slack_webhook'}
        style={{ width: '100%', height: '100%' }}
        sx={{ flex: 1 }}
      >
        <Typography fontSize={12}>Slack</Typography>
      </ToggleButton>
      <ToggleButton
        value={'fink_gpt'}
        style={{ width: '100%', height: '100%' }}
        sx={{ flex: 1 }}
      >
        <Typography fontSize={12}>Fink GPT</Typography>
      </ToggleButton>
      <ToggleButton
        value={'pager_duty'}
        style={{ width: '100%', height: '100%' }}
        sx={{ flex: 1 }}
      >
        <Typography fontSize={12} sx={{ flex: 1 }}>Pager Duty</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
