import React from 'react'
import { Box, Container, CircularProgress, useTheme } from '@mui/material'

export function Loading (): React.JSX.Element {
  const { palette } = useTheme()

  return (
    <Box width={'100%'} sx={{ backgroundColor: palette.background.paper }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
        >
          <CircularProgress sx={{ color: palette.accent.main }}/>
        </Box>
      </Container>
    </Box>
  )
}

export default Loading
