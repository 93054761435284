import React from 'react'
import { Box, Container, useTheme, Typography } from '@mui/material'

export function NeedsVerification (): React.JSX.Element {
  const { palette } = useTheme()

  return (
    <Box
      width={'100%'}
      sx={{
        backgroundColor: palette.background.paper,
        color: palette.tableBodyText.main
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
        >
          <Typography variant='h4'>Permissions Needed</Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default NeedsVerification
