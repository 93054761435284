import React, { useState, useMemo, type Key } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  ValueCell,
  type Order,
  getComparator,
  stableSort,
  TableSkeleton,
  ColumnDefinition
} from '@r40cap/ui'

import usePermissions from '../../sdk/usePermissions'
import { alertColumns } from './constants'
import type { AlertRow, InputType } from '../../types'
import type { Alert } from '../../sdk/types'
import SortableHeader from '../utils/SortableHeader'

function AlertsTableBody (props: {
  rows: AlertRow[]
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof Alert
  ) => void
  columns: Array<ColumnDefinition<AlertRow, InputType, Alert, string>>
}): React.JSX.Element {
  const { rows, handleOpenEdit, columns } = props
  const { userId } = usePermissions()
  const { palette } = useTheme()
  const navigate = useNavigate()

  return <TableBody>
    {
      rows.map((row, index) => {
        return (
          <TableRow
            key={row.id}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                index % 2 === 0
                  ? palette.primary.main
                  : palette.tertiary.main
            }}
          >
            {columns.map((column) => (
              <ValueCell<AlertRow, any>
                column={column}
                item={row}
                key={column.id as Key}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
                onDoubleClick={() => {
                  if (column.editId !== undefined && column.inputType !== undefined && row.userId === userId) {
                    handleOpenEdit(
                      row.id,
                      column.inputType,
                      column.label,
                      column.editId
                    )
                  }
                }}
              />
            ))}
            <TableCell
              key={'delete-button'}
              align='center'
              sx={{ width: '10%' }}
            >
              <Button
                variant='outlined'
                onClick={() => { navigate(`/alerts/delete/${row.id}`) }}
                sx={{
                  backgroundColor: palette.accent.main,
                  color: palette.background.default,
                  '&:disabled': {
                    opacity: 0.5,
                    color: palette.background.default
                  }
                }}
                disabled={row.userId !== userId}
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        )
      })
    }
    {
      rows.length === 0
        ? <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={columns.length + 1}
              sx={{ justifyItems: 'center', alignItems: 'center' }}
            >
              <Typography textAlign={'center'}>No Alerts</Typography>
            </TableCell>
          </TableRow>
        : <TableRow sx={{ height: '100%' }} />
    }
  </TableBody>
}

function AlertsTable (props: {
  rows: readonly AlertRow[]
  isFetching: boolean
  handleOpenEdit: (
    itemId: string,
    inputType: InputType,
    label: string,
    editProperty: keyof Alert
  ) => void
}): React.JSX.Element {
  const {
    rows,
    isFetching,
    handleOpenEdit
  } = props
  const { palette } = useTheme()
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof AlertRow>('account')

  const handleRequestSort = (property: keyof AlertRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  )

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        borderRadius: '5px',
        height: '100%'
      }}
    >
      <Table
        stickyHeader
        sx={{
          tableLayout: 'fixed',
          height: '100%'
        }}
      >
        <SortableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={alertColumns}
          aditionalEndingKeys={['delete-button']}
        />
        {
          isFetching
            ? <TableSkeleton
              numRows={10}
              columns={alertColumns}
              endBuffer={{
                key: 'delete-button',
                variant: 'rectangular',
                alignment: 'center'
              }}
            />
            : <AlertsTableBody
              rows={visibleRows}
              handleOpenEdit={handleOpenEdit}
              columns={alertColumns}
            />
        }
      </Table>
    </TableContainer>
  )
}

export default AlertsTable
