import { type ColumnDefinition } from '@r40cap/ui'

import { type AlertRow, InputType } from '../../types'
import type { Alert } from '../../sdk/types'

export const alertColumns: Array<ColumnDefinition<AlertRow, InputType, Alert, string>> = [
  {
    label: 'Account',
    id: 'account',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    inputType: InputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Type',
    id: 'alertType',
    columnWidthPercentage: 6,
    overflowType: 'scroll',
    inputType: InputType.ALERT_TYPE,
    editId: 'alertType',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Method',
    id: 'alertDeliveryMethod',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    inputType: InputType.DELIVERY_METHOD,
    editId: 'alertDeliveryMethod',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Delivery ID',
    id: 'deliveryIdentifier',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    inputType: InputType.STRING,
    editId: 'deliveryIdentifier',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Side',
    id: 'alertSide',
    columnWidthPercentage: 4,
    overflowType: 'scroll',
    inputType: InputType.ALERT_SIDE,
    editId: 'alertSide',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    signOverrideId: 'alertSideSign'
  },
  {
    label: 'Threshold',
    id: 'healthThreshold',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    inputType: InputType.FLOAT,
    editId: 'healthThreshold',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Cooldown',
    id: 'cooldownMinutes',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    inputType: InputType.POS_INTEGER,
    editId: 'cooldownMinutes',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: ' min'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Currency',
    id: 'currencyId',
    columnWidthPercentage: 7,
    overflowType: 'scroll',
    inputType: InputType.STRING,
    editId: 'currencyId',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Movement',
    id: 'movement',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    inputType: InputType.POS_FLOAT,
    editId: 'movement',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '± ',
      suffix: '%'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'User',
    id: 'user',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]
