import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, useTheme } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'

import { isApiError } from '../errors'
import AccountCreator from '../sdk/AccountCreator'
import type { Account } from '../sdk/types'
import { configApi } from '../sdk/reducers'


function AddAccountModal (): React.JSX.Element {
  const navigate = useNavigate()
  const { palette } = useTheme()
  const { showSnackbar } = useRequestSnackbar()
  const [postAdditions] = configApi.useAddAccountsMutation()

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/config')
    }
  }

  function addAccount (account: Account): void {
    showSnackbar({
      isOpen: true,
      message: 'Creating Account',
      status: 'processing'
    })
    postAdditions({
      additions: [account]
    })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.status === 400 || value.error.status === 403
            ? value.error.data.error
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          showSnackbar({
            isOpen: true,
            message: 'Account Created',
            status: 'success'
          })
          exitModal()
        }
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to create Account',
          status: 'error'
        })
      })
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box
        sx={{
          width: '15vw',
          padding: '20px'
        }}
      >
        <AccountCreator
          submitFunction={addAccount}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          spacing={2}
        />
      </Box>
    </Modal>
  )
}

export default AddAccountModal
