export enum InputType {
  STRING,
  COUNTERPARTY
}

export interface AccountRow {
  id: string
  name: string
  counterparty: string
  apiSecret: string
  apiId: string
}
