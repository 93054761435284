export interface ApiError {
  data: {
    error: string
  }
  status: number
}

export function isApiError (error: any): error is ApiError {
  return error !== undefined && error.data !== undefined && error.status !== undefined && error.data.error !== undefined
}
