import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import {
  IS_AUTHENTICATED_SLUG
} from './slugs'
import type {
  GetResponse,
  Permissions
} from '../types'

export const permissionsApi = createApi({
  reducerPath: 'permissionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production'
      ? 'https://api.risk.room40.io/'
      : 'http://127.0.0.1:8000/'
    ),
    fetchFn: fetch,
    credentials: 'include'
  }),
  tagTypes: ['AuthStatus'],
  endpoints: (builder) => ({
    getPermissions: builder.query<GetResponse<Permissions> | null, undefined>({
      query () {
        return {
          url: `${IS_AUTHENTICATED_SLUG}/`,
          method: 'GET'
        }
      },
      providesTags: ['AuthStatus']
    })
  })
})

export const {
  useGetPermissionsQuery
} = permissionsApi
