import React, { useEffect, useCallback, useState } from 'react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

import { permissionsApi } from '../reducers'
import { PermissionsContext } from './usePermissions'
import NeedsVerification from './views/NeedsVerification'
import Loading from './views/Loading'
import Login from './views/Login'

const REFRESH_INTERVAL = 2 * 60 * 1000

export const PermissionsProvider = (props: {
  children: React.JSX.Element | React.JSX.Element[] | string
}) => {
  const { children } = props
  const { data, isLoading, refetch, error } = permissionsApi.useGetPermissionsQuery(undefined)
  const [needsAdminVerification, setNeedsAdminVerification] = useState(false)
  const [needsLogin, setNeedsLogin] = useState(false)

  useEffect(() => {
    if (error) {
      const err = error as FetchBaseQueryError
      if (err.status === 403) {
        setNeedsAdminVerification(true)
        setNeedsLogin(false)
      } else {
        setNeedsLogin(true)
        setNeedsAdminVerification(false)
      }
    } else {
      setNeedsAdminVerification(false)
      setNeedsLogin(false)
    }
  }, [error])

  const refreshPermissions = useCallback(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    const intervalId = setInterval(refreshPermissions, REFRESH_INTERVAL)
    return () => clearInterval(intervalId)
  }, [refreshPermissions])

  const value = {
    userId: data?.data.userId,
    username: data?.data.username,
    hasCoinbaseAccess: data?.data.hasCoinbaseAccess || false,
    hasStonexAccess: data?.data.hasStonexAccess || false,
    hasOkxAccess: data?.data.hasOkxAccess || false,
    hasConfigAccess: data?.data.hasConfigAccess || false,
    hasRpmsAccess: data?.data.hasRpmsAccess || false,
    hasAlertsAccess: data?.data.hasAlertsAccess || false
  }

  if (needsAdminVerification) {
    return <NeedsVerification />
  }

  if (needsLogin) {
    return <Login onLoginComplete={refreshPermissions} />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <PermissionsContext.Provider value={value}>
      {children}
    </PermissionsContext.Provider>
  )
}
