import React from 'react'
import { Button, Stack, useTheme } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const RPMS_URL = 'https://rpms.room40.io'

function ToRpmsButton (): React.JSX.Element {
  const handleClick = () => {
    window.open(RPMS_URL, '_blank')
  }

  const { palette } = useTheme()

  return (
    <Button
      variant='outlined'
      onClick={handleClick}
      sx={{
        color: palette.accent.main,
        borderColor: palette.accent.main
      }}
    >
      <Stack direction='row' spacing={1}>
        <p>RPMS</p>
        <OpenInNewIcon />
      </Stack>
    </Button>
  )
}

export default ToRpmsButton
