import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, useTheme } from '@mui/material'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'

import { getMode, themeToggle } from './themeSlice'

export function ThemeToggle (): React.JSX.Element {
  const mode = useSelector(getMode)
  const dispatch = useDispatch()
  const { palette } = useTheme()
  const iconColor = palette.themeToggleIcon.main
  const isLightMode = mode === 'light'

  function toggleTheme (): void {
    dispatch(themeToggle())
  }

  return (
    <div>
      <Button
        variant={isLightMode ? 'contained' : 'outlined'}
        onClick={() => { toggleTheme() }}
        color="themeToggleBackground"
      >
        {isLightMode
          ? (
              <DarkModeIcon sx={{ color: iconColor }} />
            )
          : (
              <LightModeIcon sx={{ color: iconColor }} />
            )}
      </Button>
    </div>
  )
}
