import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { RequestSnackbar, RequestSnackbarProvider } from '@r40cap/ui'

import themeOptions from './themes/theme'
import { getMode } from './features/theme/themeSlice'
import PageHandler from './PageHandler'
import { PermissionsProvider } from './sdk/usePermissions'
import { EventProvider } from './context/EventContext'

function App (): React.JSX.Element {
  const mode = useSelector(getMode)
  const theme = createTheme(themeOptions(mode))

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <RequestSnackbarProvider>
          <PermissionsProvider>
            <EventProvider>
              <PageHandler/>
              <RequestSnackbar
                loadingColor={theme.palette.accent.main}
              />
            </EventProvider>
          </PermissionsProvider>
        </RequestSnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
