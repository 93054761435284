import { type ColumnDefinition } from '@r40cap/ui'

import { type AccountRow, InputType } from './types'
import type { Account } from '../../sdk/types'

export const accountColumns: Array<ColumnDefinition<AccountRow, InputType, Account, string>> = [
  {
    label: 'Name',
    id: 'name',
    columnWidthPercentage: 25,
    overflowType: 'scroll',
    inputType: InputType.STRING,
    editId: 'name',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Counterparty',
    id: 'counterparty',
    columnWidthPercentage: 25,
    overflowType: 'scroll',
    inputType: InputType.COUNTERPARTY,
    editId: 'counterparty',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'API ID',
    id: 'apiId',
    columnWidthPercentage: 30,
    overflowType: 'scroll',
    inputType: InputType.STRING,
    editId: 'apiId',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'API Secret',
    id: 'apiSecret',
    columnWidthPercentage: 20,
    overflowType: 'scroll',
    inputType: InputType.STRING,
    editId: 'apiSecret',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]
