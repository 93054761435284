import React, { useState, useEffect } from 'react'
import { Autocomplete, type AutocompleteOption } from '@r40cap/ui'

import { configApi } from '../reducers'
import type { Counterparty } from '../types'

interface CounterpartyInputProps {
  setCounterparty: (counterparty: Counterparty | null) => void
  mainColor: string
  secondaryColor: string
  prefillCounterpartyId?: string | null
}

export function CounterpartyInput (props: CounterpartyInputProps): React.JSX.Element {
  const {
    setCounterparty,
    mainColor,
    secondaryColor,
    prefillCounterpartyId
  } = props
  const { data } = configApi.useGetCounterpartiesQuery(undefined)
  const [counterpartyOptions, setCounterpartyOptions] = useState<ReadonlyArray<AutocompleteOption<string>>>([])
  const [counterpartyChoice, setCounterpartyChoice] = useState<AutocompleteOption<string> | null>(null)

  function trySetCounterparty (id: string | null): void {
    if (id === null) {
      setCounterparty(null)
    } else {
      const found = data?.data.find((data) => data.counterpartyId === id)
      setCounterparty(found ?? null)
    }
  }

  useEffect(() => {
    if (data !== null && data !== undefined) {
      const options = data.data.map((counterparty) => {
        return {
          id: counterparty.counterpartyId,
          label: counterparty.name
        }
      })
      setCounterpartyOptions(options)
    }
  }, [data])

  return <Autocomplete<string>
    trySetObject={trySetCounterparty}
    options={counterpartyOptions}
    choice={counterpartyChoice}
    setChoice={setCounterpartyChoice}
    label='Counterparty'
    activeColor={mainColor}
    inactiveColor={secondaryColor}
    prefillId={prefillCounterpartyId ?? undefined}
  />
}
