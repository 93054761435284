import React from 'react'
import { Box, Container } from '@mui/material'

export function PageWrapper (props: {
  children: string | React.JSX.Element | React.JSX.Element[]
}): React.JSX.Element {
  const { children } = props

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%'
        }}
      >
        {children}
      </Box>
    </Container>
  )
}

export default PageWrapper
