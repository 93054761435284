import React from 'react'
import { Box, Container, useTheme } from '@mui/material'
import { AuthGateway } from '@r40cap/auth-new'

export function Login (props: { onLoginComplete: () => void }): React.JSX.Element {
  const { onLoginComplete } = props
  const { palette } = useTheme()

  return (
    <Box width={'100%'} sx={{ backgroundColor: palette.background.paper }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
        >
          <AuthGateway
            inputColor={palette.accent.main}
            logoColor={palette.logoColor.main}
            qrCodeBackgroundColor={palette.background.paper}
            qrCodeForegroundColor={palette.tableHeaderText.main}
            onLoginComplete={onLoginComplete}
          />
        </Box>
      </Container>
    </Box>
  )
}

export default Login
