import { type ColumnDefinition } from '@r40cap/ui'

import { InputType, type CollateralRow, type PositionRow } from '../types'
import type { CollateralItem, PositionItem } from '../sdk/types'

export const collateralColumns: Array<ColumnDefinition<CollateralRow, InputType, CollateralItem>> = [
  {
    label: 'Asset',
    id: 'ticker',
    columnWidthPercentage: 40,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Balance',
    id: 'balance',
    columnWidthPercentage: 30,
    overflowType: 'scroll',
    inputType: InputType.FLOAT,
    editId: 'balance',
    sortable: true,
    colored: false,
    decimalsId: 'quantityDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Price',
    id: 'price',
    columnWidthPercentage: 30,
    overflowType: 'scroll',
    inputType: InputType.FLOAT,
    editId: 'price',
    sortable: true,
    colored: false,
    decimalsId: 'priceDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]

export const positionColumns: Array<ColumnDefinition<PositionRow, InputType, PositionItem>> = [
  {
    label: 'Position',
    id: 'ticker',
    columnWidthPercentage: 30,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Quantity',
    id: 'quantity',
    columnWidthPercentage: 30,
    overflowType: 'scroll',
    inputType: InputType.FLOAT,
    editId: 'quantity',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Price',
    id: 'price',
    columnWidthPercentage: 30,
    overflowType: 'scroll',
    inputType: InputType.FLOAT,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]
