import React, { useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { NumberInput, StringInput, useRequestSnackbar } from '@r40cap/ui'

import type { Account, Alert, AlertDeliveryMethod, AlertSide, AlertType } from '../types'
import AccountInput from '../AccountInput'
import AlertDeliveryMethodInput from '../AlertDeliveryMethodInput'
import AlertTypeInput from '../AlertTypeInput'
import AlertSideInput from '../AlertSideInput'

interface AlertCreatorProps {
  mainColor: string
  secondaryColor: string
  submitFunction: (newAlert: Alert) => void
  spacing?: number
}

export function AlertCreator (props: AlertCreatorProps): React.JSX.Element {
  const {
    mainColor,
    secondaryColor,
    submitFunction,
    spacing
  } = props
  const [account, setAccount] = useState<Account | null>(null)
  const [alertType, setAlertType] = useState<AlertType>('live')
  const [alertSide, setAlertSide] = useState<AlertSide>('under')
  const [alertDeliveryMethod, setAlertDeliveryMethod] = useState<AlertDeliveryMethod>('fink_gpt')
  const [deliveryMethodId, setDeliveryMethodId] = useState<string>('')
  const [currencyId, setCurrencyId] = useState<string>('')
  const [movementStr, setMovementStr] = useState<string>('')
  const [thresholdStr, setThresholdStr] = useState<string>('')
  const [cooldownMinutesStr, setCooldownMinutesStr] = useState<string>('')
  const { showSnackbar } = useRequestSnackbar()

  const usedSpacing = spacing ?? 1

  function handleSetAlertType (newType: AlertType): void {
    if (alertType === 'live') {
      setCurrencyId('')
      setMovementStr('')
    }
    setAlertType(newType)
  }
  
  function handleSetAlertDeliveryMethod (newMethod: AlertDeliveryMethod): void {
    if (newMethod !== 'slack_webhook') {
      setDeliveryMethodId('')
    }
    setAlertDeliveryMethod(newMethod)
  }

  function handleSubmit (): void {
    if (account === null) {
      showSnackbar({
        status: 'error',
        message: 'Select an account',
        isOpen: true
      })
    } else if (alertDeliveryMethod === 'slack_webhook' && deliveryMethodId === '') {
      showSnackbar({
        status: 'error',
        message: 'Provide webhook name as Method ID',
        isOpen: true
      })
    } else if (alertType === 'shocked' && (currencyId === '' || movementStr === '')) {
      showSnackbar({
        status: 'error',
        message: 'Provide currency and size for shock',
        isOpen: true
      })
    } else if (thresholdStr === '') {
      showSnackbar({
        status: 'error',
        message: 'Set account health threshold',
        isOpen: true
      })
    } else if (cooldownMinutesStr === '') {
      showSnackbar({
        status: 'error',
        message: 'Set notification cooldown minutes',
        isOpen: true
      })
    } else {
      const newAlert: Alert = {
        alertId: '',
        account: {
          accountId: account.accountId,
          name: account.name,
          counterpartyId: account.counterparty.counterpartyId,
          counterpartyName: account.counterparty.name
        },
        alertDeliveryMethod,
        alertType,
        alertSide,
        deliveryIdentifier: deliveryMethodId === '' ? undefined : deliveryMethodId,
        user: { userId: '', username: '' },
        currencyId: currencyId === '' ? undefined : currencyId,
        movement: movementStr === '' ? undefined : Math.abs(parseFloat(movementStr)) / 100,
        healthThreshold: parseFloat(thresholdStr),
        cooldownMinutes: Math.abs(parseInt(cooldownMinutesStr))
      }
      submitFunction(newAlert)
    }
  }

  return (
    <Stack
      spacing={usedSpacing}
      sx={{ width: '100%' }}
    >
      <AccountInput
        setAccount={setAccount}
        mainColor={mainColor}
        secondaryColor={secondaryColor}
      />
      <AlertTypeInput
        type={alertType}
        setType={handleSetAlertType}
      />
      <Stack direction={'row'} spacing={1} sx={{ width: '100%' }}>
        <AlertDeliveryMethodInput
          deliveryMethod={alertDeliveryMethod}
          setDeliveryMethod={handleSetAlertDeliveryMethod}
        />
        {
          alertDeliveryMethod === 'slack_webhook'
            ? <StringInput
                title={'Delivery ID'}
                value={deliveryMethodId}
                setValue={setDeliveryMethodId}
                color={mainColor}
              />
            : <Box sx={{ width: '100%', height: '100%' }}/>
        }
      </Stack>
      {
        alertType === 'shocked' && <Stack direction={'row'} spacing={1} sx={{ width: '100%' }}>
          <Box sx={{ width: '50%' }}>
            <StringInput
              title={'Shock Currency'}
              value={currencyId}
              setValue={setCurrencyId}
              color={mainColor}
            />
          </Box>
          <Stack direction={'row'} sx={{ width: '50%' }}>
            <Box sx={{ width: '80%' }}>
              <NumberInput
                title={'Shock Size'}
                intOnly={false}
                lowerBound={0}
                valueString={movementStr}
                setValueString={setMovementStr}
                submit={(value: number) => {}}
                color={mainColor}
              />
            </Box>
            <Box sx={{ width: '20%' }}>
              <Typography
                textAlign={'center'}
                fontSize={'1.5rem'}
                sx={{
                  height: '100%',
                  color: secondaryColor,
                  alignContent: 'center',
                  justifyContent: 'center'
                }}
              >
                %
              </Typography>
            </Box>
          </Stack>
        </Stack>
      }
      <Stack direction={'row'} sx={{ width: '100%' }} spacing={1}>
        <AlertSideInput
          side={alertSide}
          setSide={setAlertSide}
        />
        <NumberInput
          title={'Alert Threshold'}
          intOnly={false}
          valueString={thresholdStr}
          setValueString={setThresholdStr}
          submit={(value: number) => {}}
          color={mainColor}
        />
        <NumberInput
          title={'Cooldown Minutes'}
          intOnly={true}
          valueString={cooldownMinutesStr}
          setValueString={setCooldownMinutesStr}
          submit={(value: number) => {}}
          color={mainColor}
        />
      </Stack>
      <Button
        onClick={handleSubmit}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: mainColor,
          color: secondaryColor
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}
