import React from 'react'

import { InputType } from './types'
import {
  StringEditContent,
  CounterpartyEditContent
} from '../modals/inputs'
import type { Account } from '../../sdk/types'

export function getModalContent (
  inputType: InputType,
  label: string,
  editProperty: keyof Account,
  closeModal: () => void,
  handleSubmission: (value: any, selectedIds: readonly string[], property?: keyof Account) => void,
  newSelected: readonly string[],
  setSelected: (values: string[]) => void,
  initialValue?: string | null
): React.JSX.Element {
  let content: React.JSX.Element = <></>

  function submit (value: any): void {
    handleSubmission(value, newSelected, editProperty)
    setSelected([])
  }

  switch (inputType) {
    case InputType.COUNTERPARTY:
      content = <CounterpartyEditContent
        closeModal={closeModal}
        submit={submit}
      />
      break
    default:
      content = <StringEditContent
        closeModal={closeModal}
        submit={submit}
        title={label}
      />
  }
  return content
}
