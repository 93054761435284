import React, { useState } from 'react'
import { Button, Stack } from '@mui/material'
import { StringInput } from '@r40cap/ui'

import type { Account, Counterparty } from '../types'
import CounterpartyInput from '../CounterpartyInput'

interface AccountCreatorProps {
  mainColor: string
  secondaryColor: string
  submitFunction: (newRow: Account) => void
  spacing?: number
  prefillCounterpartyId?: string
}

export function AccountCreator (props: AccountCreatorProps): React.JSX.Element {
  const {
    mainColor,
    secondaryColor,
    submitFunction,
    spacing,
    prefillCounterpartyId
  } = props
  const [counterparty, setCounterparty] = useState<Counterparty | null>(null)
  const [accountId, setAccountId] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [apiSecret, setApiSecret] = useState<string>('')
  const [apiId, setApiId] = useState<string>('')

  const usedSpacing = spacing ?? 1

  function handleSubmit (): void {
    if (name !== '' && accountId !== null && counterparty !== null) {
      const newRow: Account = {
        accountId,
        name,
        counterparty,
        apiSecret: apiSecret === '' ? undefined : apiSecret,
        apiId: apiId === '' ? undefined : apiId
      }
      submitFunction(newRow)
    }
  }

  return (
    <Stack
      spacing={usedSpacing}
      sx={{ width: '100%' }}
    >
      <StringInput
        title='Account ID'
        value={accountId}
        setValue={setAccountId}
        color={mainColor}
        enterSubmit={handleSubmit}
      />
      <StringInput
        title='Name'
        value={name}
        setValue={setName}
        color={mainColor}
        enterSubmit={handleSubmit}
      />
      <CounterpartyInput
        setCounterparty={setCounterparty}
        mainColor={mainColor}
        secondaryColor={secondaryColor}
        prefillCounterpartyId={prefillCounterpartyId}
      />
      <StringInput
        title='API ID'
        value={apiId}
        setValue={setApiId}
        color={mainColor}
        enterSubmit={handleSubmit}
      />
      <StringInput
        title='API Secret'
        value={apiSecret}
        setValue={setApiSecret}
        color={mainColor}
        enterSubmit={handleSubmit}
      />
      <Button
        onClick={handleSubmit}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: mainColor,
          color: secondaryColor
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}
